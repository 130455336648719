import React                                              from "react";
import { useLocation }                                    from "@relcu/react-router";
import { CommonClasses }                                  from "@relcu/ui";
import { TimelineItem }                                   from "@relcu/ui";
import { TimelineItemIcon }                               from "@relcu/ui";
import { TimelineItemIconColors }                         from "@relcu/ui";
import { TimelineItemSubject }                            from "@relcu/ui";
import { Typography }                                     from "@relcu/ui";
import { TypographyColor }                                from "@relcu/ui";
import { Link }                                           from "@relcu/react-router";
import { BulkAssignmentActivity as BulkAssignmentActivityType } from "../../../../__types__/BulkAssignmentActivity";

export interface BulkAssignmentActivityProps extends BulkAssignmentActivityType {
    createdAt: string;
}

export const BulkAssignmentActivity = React.memo<BulkAssignmentActivityProps>(function BulkAssignmentActivity(props) {
    const { number, assignedBy, assignedTo, createdAt } = props;
    const location = useLocation();
    const { pathname } = location;

    const initiatorName = assignedBy ? assignedBy.objectName : "Relcu";
    const to = assignedTo ? assignedTo.objectName : "Unknown";

    return <TimelineItem
        avatar={<TimelineItemIcon variant={TimelineItemIconColors.Default} icon={"group"}/>}
        primary={assignedBy?.objectId === pathname?.split('/')?.[2]? <TimelineItemSubject>
            {assignedBy?.objectId && !assignedBy?.objectId.startsWith("int:") ?
                <Link to={`/user/${assignedBy.objectId}/lead`}>
                    <Typography className={CommonClasses.ClickableIcon}>{initiatorName}</Typography>
                </Link>
                :
                <Typography>{initiatorName}</Typography>
            }
            <Typography color={TypographyColor.Secondary}>assigned</Typography>
            <Typography>{number? number: 'not found'}</Typography>
            <Typography color={TypographyColor.Secondary}>leads to</Typography>
            {assignedTo?.objectId && !assignedTo?.objectId.startsWith("int:") ?
                <Link to={`/user/${assignedTo.objectId}/lead`}>
                    <Typography className={CommonClasses.ClickableIcon}>{to}</Typography>
                </Link>
                :
                <Typography>{to}</Typography>
            }
        </TimelineItemSubject> :
        <TimelineItemSubject>
            {assignedTo?.objectId && !assignedTo?.objectId.startsWith("int:") ?
                <Link to={`/user/${assignedTo.objectId}/lead`}>
                    <Typography className={CommonClasses.ClickableIcon}>{to}</Typography>
                </Link>
                :
                <Typography>{to}</Typography>
            }
            <Typography color={TypographyColor.Secondary}>was assigned to</Typography>
            <Typography>{number? number: 'not found'}</Typography>
            <Typography color={TypographyColor.Secondary}>leads by</Typography>
            {assignedBy?.objectId && !assignedBy?.objectId.startsWith("int:") ?
                <Link to={`/user/${assignedBy.objectId}/lead`}>
                    <Typography className={CommonClasses.ClickableIcon}>{initiatorName}</Typography>
                </Link>
                :
                <Typography>{initiatorName}</Typography>
            }
        </TimelineItemSubject>}
        creationDate={createdAt}>
    </TimelineItem>;
});
